
import {
    PropType, ref, reactive, defineComponent
} from 'vue';
import { user } from '@/methods/rule';
import { getAllowInstallerDistributor, addSubDistributor, editSubDistributor } from '@/api/common/distributor';
import { resetPwdManage } from '@/api/common/manage';
import { ElMessageBox } from 'element-plus';

export default defineComponent({
    props: {
        type: {
            type: String as PropType<'add'|'edit'>,
            required: true
        },
        // 详情数据
        detailData: {
            type: Object,
            default: () => ({})
        }

    },
    setup(props, { emit }) {
        const dialogVisible = ref(true);
        const formData = reactive({
            ID: '',
            UUID: '',
            Account: '',
            Email: '',
            Phone: '',
            Company: '',
            PayBySubDis: '0',
            InstallerUUIDs: []
        });
        // 详情数据赋值
        Object.keys(props.detailData).forEach((key) => {
            // @ts-ignore
            formData[key] = props.detailData[key];
        });

        const rules = {
            Account: [{
                required: true,
                message: WordList.RuleAccountEmpty,
                trigger: 'blur'
            }, {
                validator: user.checkManageAccount,
                trigger: 'blur'
            }],
            Phone: [{
                validator: user.checkManageAccount,
                trigger: 'blur'
            }],
            Company: [{
                validator: user.checkManageAccount,
                trigger: 'blur'
            }],
            Email: [{
                validator: user.checkEmail,
                trigger: 'blur'
            }]
        };
        // ins数据
        const allowInstaller = ref([]);
        getAllowInstallerDistributor({ SubDistributorUUID: props.detailData.UUID || '' }, (res: any) => {
            allowInstaller.value = res;
        });
        function resetPwd() {
            resetPwdManage({ ID: formData.ID }, [
                (res: { msg: string }) => {
                    ElMessageBox.alert(res.msg, WordList.Alert, {
                        confirmButtonText: WordList.TabelFootSure,
                        type: 'success'
                    });
                }, false
            ]);
        }

        const formRef = ref();
        function submit() {
            const apiFn = props.type === 'add' ? addSubDistributor : editSubDistributor;
            formRef.value.validate((valid: boolean) => {
                if (valid) {
                    const { InstallerUUIDs, ...other } = formData;
                    apiFn({ InstallerUUIDs: JSON.stringify(InstallerUUIDs), ...other }, [
                        (res: any) => {
                            emit('submitSubDistributorSuccess');
                            dialogVisible.value = false;
                            ElMessageBox.alert(res.msg, '', {
                                confirmButtonText: WordList.TabelFootSure,
                                type: 'success'
                            });
                        }, false
                    ]);
                } else {
                    return false;
                }
            });
        }
        return {
            dialogVisible,
            formData,
            allowInstaller,
            rules,
            submit,
            formRef,
            props,
            resetPwd
        };
    }
});

